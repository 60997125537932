<template>
    <custom-table
        :title="$t('pages.ecommerce.productManagement.product.title')"
        :subTitle="$t('pages.ecommerce.productManagement.product.subTitle')"
        selectableRows
        rowKey="id"
        :items="products"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        filterComponentName="EcommerceProductFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:title="{ row: record }">
            <a class="fw-bold text-gray-600 text-hover-primary" :href="$root.appUrl + '/' + record.defaultLanguageTranslate.slug" target="_blank">{{ record.defaultLanguageTranslate.title }}</a>
        </template>
        <template v-slot:price="{ row: record }">
            {{ record.defaultCurrencyPrice.price + " " + $root.defaultCurrency.suffix }}
        </template>
        <template v-slot:discountPrice="{ row: record }">
            {{ record.defaultCurrencyPrice.discount_price ? record.defaultCurrencyPrice.discount_price + " " + $root.defaultCurrency.suffix : "-" }}
        </template>
        <template v-slot:soldOutCount="{ row: record }">
            {{ record.sold_out_count ?? "-" }}
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.ecommerce.productManagement.product.showCanUseModule')" placement="left" v-if="record.can_use_module">
                    <a v-on:click="showCanUseModule(record.id)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <router-link :to="'/ecommerce/product-management/product/save/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg" />
                     </span>
                </router-link>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_show_can_use_module" tabindex="-1" aria-hidden="true"  ref="canUseModuleModal">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header flex-stack">
                    <h2>{{  $t("pages.ecommerce.productManagement.product.chooseModule") }}</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y pt-10 pb-15 px-lg-17">
                    <div>
                        <div class="pb-10">
                            <div v-for="(module, moduleIndex) in canUseModule.list" v-bind:key="moduleIndex" v-show="module.code != 'DOCUMENT'">
                                <input type="radio" class="btn-check" v-model="canUseModule.model" :value="moduleIndex" :id="'module_option_'+(moduleIndex + 1)"/>
                                <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5" :for="'module_option_'+(moduleIndex + 1)">
                                    <span class="svg-icon svg-icon-4x me-4">
                                        <inline-svg src="/media/icons/duotune/general/gen055.svg" />
                                    </span>
                                    <span class="d-block fw-bold text-start">
                                        <span class="text-dark fw-bolder d-block fs-3">{{ module.name }}</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <button @click="selectedModule" :disabled="canUseModule.model == undefined" class="btn btn-primary w-100">
                            {{  $t('common.confirm') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "product",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.group"),
                    key: "group.name"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.type"),
                    key: "type.name"
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.price"),
                    scopedSlots: {customRender: "price"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.discountPrice"),
                    scopedSlots: {customRender: "discountPrice"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.stock"),
                    key: "stock",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.soldOutCount"),
                    scopedSlots: {customRender: "soldOutCount"}
                },
                {
                    name: this.$t("pages.ecommerce.productManagement.product.cols.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("pages.system.admin.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: [],
            canUseModule: {
                list: [],
                model: undefined,
                productID: undefined
            }
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.productManagement.product.table;
        },
        products(){
            let products = this.table.data.map((product) => {
                product.defaultLanguageTranslate =  this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id');
                product.defaultCurrencyPrice =  this.resolveDatum(product.prices, this.$root.defaultCurrency.id, 'currency_id');

                return product;
            });

            return products;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.products"), [this.$t("menu.productManagement")]);

        this.$store.dispatch('ecommerce/productManagement/product/get', {
            page: {
                current: this.$route.query.page
            },
            filterData: this.$root.getFilterWithUrl()
        });
    },
    methods:{
        deleteRecord(id){
            this.$store.dispatch("ecommerce/productManagement/product/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/ecommerce/product-management/product/save"
                    });
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("ecommerce/productManagement/product/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/productManagement/product/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        showCanUseModule(productID) {
            this.canUseModule.model = undefined;
            this.axios.get(this.endpoints['ecommerce_product_operation'] + '/' + productID).then((response) => {
                let data = response.data.data;
                this.canUseModule.list = data;
                this.canUseModule.productID = productID;
                this.showModal(this.$refs.canUseModuleModal);
            })
        },
        selectedModule(){
            let hiddenFunction = () => {
                this.$refs.canUseModuleModal.removeEventListener('hidden.bs.modal', hiddenFunction);
                this.$router.push({
                    path: "/module/"+ this.canUseModule.list[this.canUseModule.model].code.toLowerCase().replace('_', '-'),
                    query: {productID: this.canUseModule.productID}
                });
            };

            this.$refs.canUseModuleModal.addEventListener('hidden.bs.modal', hiddenFunction);

            this.hideModal(this.$refs.canUseModuleModal);
        }
    }
}
</script>

<style scoped>

</style>